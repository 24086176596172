$title-font-size: 1.5rem;
$body-font-size: 1rem;
$heading-font-size: 1.5rem;
$background-color: #FFF;
$font-color: #000;

.projects, .mobile-projects {
  -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s; /* Firefox < 16 */
   -ms-animation: fadein 0.2s; /* Internet Explorer */
    -o-animation: fadein 0.2s; /* Opera < 12.1 */
       animation: fadein 0.2s;
}

.title {
  text-align: left;
  font-size: $heading-font-size;
  padding: 0rem 0rem 1rem 0rem;
  text-transform: uppercase;
}

.blurb {
  display: none;
}

#backButton {
}

.projectModule {
  background-color: black;
}

.projectModule:hover > .blurb {
  display: block;
  background-color: black;
}

.projectModule:hover > img {
  visibility: hidden;
}

.project {
  width: 85vw;
  padding: 2.5vh 0 2.5vh 0;
  height: inherit;
  display: flex;
  flex-flow: column;
  align-self: flex-start;
}

.projectContainer {
  height: 100%;
}

.projectColumns {
  width: 85vw;
  display: flex;
  flex-flow: row;
  height: 100%;
}

.projectColumns img {
  height: inherit;
  // margin-left: auto;
  // margin-right: auto;
  max-width: 100%;
}

.Verso {
  width: 60%;
}

.Recto {
  width: 40%;
}

.projectVideo {
  width: 100%;
  height: fit-content;
}

.projectVideo > iframe {
  width: 100%;
  height: 28vw;
}

.projectTitle {
  font-size: $title-font-size;
  margin: 0.5rem 0 0rem 0;
  text-transform: uppercase;
}

.projectBlurb {
  display: none;
}

.projectDescription {
  padding: 0rem 1rem 1rem 0;
}

.projectImages {
  display: flex;
  // width: 60vw;
  flex-flow: column wrap;
  justify-content: flex-start;
}

.projectImages img {
  padding: 1rem;
  // width: 50%;
  height: auto;
  width: 95%;
}

.loading {
  position: fixed;
  bottom: 2.5vh;
  left: 15vw;
}

.bodyText {
  position: absolute;
  left: 0vw;
  align-self: center;
}


.projectDashboard {
  display: flex;
  width: auto;
  height: auto;
  padding: 2.5vh 2.5vw 2.5vh 2.5vw;
  flex-flow: row wrap;
  justify-content: flex-start;

}

.projectModule {
  height: 100%;
  display: flex;
  outline: 1px white solid;
  border-radius: 25px;
  flex-flow: column nowrap;
  justify-content: center;
}

.projectModule img {
  height: 100%;
  width: 100%;
  object-fit: cover;  
  border-radius: 25px;
}

.projectModule .title {
  text-align: center;
  width: 30%; 
  position: absolute;
  align-self: center;
  background-color: black;
  color: white;
  padding: 5px;
}

#separator {
  font-size: 2rem;
  text-align: center;
  padding: 0 2rem 2rem 2rem;
}

#contact {
}

#curatorial .projectModule, #films .projectModule, #engagement .projectModule {
  width: 40vw;
  height: 47.5vh;
}

#works .projectModule {
  width: 40vw;
}

#works .title {
  width: 20%;
}

#news .title {
  padding: 0 0 2vh 0;
  text-align: left;
}

#news .projectColumns .title {
  padding: 0;
  text-align: center;
}

img {
  display: block;
  -webkit-animation: fadein 0.2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.2s; /* Firefox < 16 */
   -ms-animation: fadein 0.2s; /* Internet Explorer */
    -o-animation: fadein 0.2s; /* Opera < 12.1 */
       animation: fadein 0.2s;
}

/*---------------------------animation---------------------------*/
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/*---------------------------MOBILE---------------------------*/

@media all and (max-width: 800px) {

.project {
  width: 90vw;
  margin: 0 5vw 0 5vw;
}

.projectColumns {
  width: inherit;
  flex-flow: column;
}

.projectColumns img {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.Verso {
  width: 100%;
}

.Recto {
  width: 100%;
}


#works .projectModule, #curatorial .projectModule, #films .projectModule, #engagement .projectModule {
  width: 100vw;
}

.projectDashboard {
  overflow-y: auto;
  height: auto;
  padding: 0;
}

.projectDescription {
  padding: 0;
  width: inherit;
}

#works .title, #curatorial .title, #films .title, #engagement .title{
  width: auto;
  max-width: 80%;
}

.projectImages {
  width: 90%;
}

.projectTitle {
  font-size: 2rem;
}

}
