body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Akzidenz Grotesk Light';
  src: url(assets/fonts/akzidenzgrotesk-light-webfont.woff);
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url(assets/fonts/IBMPlexMono-Light.woff);
  font-style: normal;
}

@font-face {
  font-family: 'IBM Plex Mono';
  src: url(assets/fonts/IBMPlexMono-LightItalic.woff);
  font-style: italic;
}
