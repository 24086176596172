$main-font: 'Akzidenz Grotesk Light', sans-serif;

$background-color: #FFF;
$font-color: #000;
$link-color: #000;
$link-hover-color: #ffc0cb;
$body-font-size: 1rem;
$heading-font-size: 1.8rem;

$menu-font-size: 1em;

$mobile-menu-color: #FFF;
$mobile-menu-text: #000;
$mobile-menu-border: 1px black solid;

.App {
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: flex-start;
  flex-flow: row nowrap;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: $link-color;
}

a:hover {
  color: $link-hover-color;
}

body {
  background-color: $background-color;
  color: $font-color;
  line-height: 1.1;
}

.App-link {
  color: #61dafb;
}

.main {
  position: absolute;
  display: flex;
  height: 100vh;
  width: 85vw;
  left: 15vw;
  justify-content: flex-start;
  flex-flow: column;
  font-size: $menu-font-size;
  overflow-y: auto;
}

.menu {
  position: fixed;
  display: flex;
  height: 100%;
  width: 15vw;
  left: 0;
  float: left;
  text-transform: uppercase;
  flex-flow: column nowrap;
  justify-content: space-between;
  transition: left 0.5s; /* Transition effect when sliding down (and up) */
  z-index: 10;
  // border-right: white 1px solid;
}

.menu-heading-container {
  width: auto;
  padding: 0 0 2.5vh 0;

}

.menu-heading {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  font-size: $menu-font-size;
  margin: 0vh 0vh 0vh 3vh;
  z-index: 1;
}

.menu-heading * {
  padding: 0.5vh 3vh 0.5vh 2vh;
  background-color: $background-color;
  color: $font-color;
}

.wp-logo {
  position: relative;
  padding: 2vh 2vh 2vh 4vh;
  width: 15vh;
  height: 15vh;
  pointer-events: none;
  // filter: invert(100%);
}

#menuClose {
  display: none;
  position: absolute;
  color: $mobile-menu-text;
  right: 2vh;
  top: 2vh;
}


@media all and (max-width: 800px) {

  .main {
    left: 0;
    width: 100vw;
    height: auto;
  }

  .menu-heading-container {
    position: fixed;
    background-color: black;
    border-radius: 15px;
    font-size: 1.5rem;
    border: $mobile-menu-border;
    bottom: 2vh;
    left: 2vh;
    padding: 2vh;
    width: auto; 
    background-color: $mobile-menu-color;
    color: black
  }

  #menuButton {
    position: fixed;
    width: auto;
    text-align: center;
    text-transform: capitalize;
    border-radius: 15px;
    border: $mobile-menu-border;
    padding: 1rem;
    background-color: $mobile-menu-color;
    color: $mobile-menu-text;
    z-index: 10;
    bottom: 2vh;
    left: 2vh;
  }

  .menu-heading {
    padding: 0;
    margin: 0;
    color: $mobile-menu-text;
  }

  .menu-heading * {
    background-color: $mobile-menu-color;
    color: $mobile-menu-text;
  }

  #menuClose {
    display: block;
}

  .wp-logo {
    display: none;
  }

}
